<template>
  <v-app />
</template>

<script>
  export default {
    name: 'Cerrar',
    created () {
      localStorage.removeItem('usuarioOdajup')
      this.$router.push('/login')
    },
  }
</script>
